<template>
    <div id="digitalDesign">
      <div class="cm-container">
        <!-- 搜索 -->
        <div class="inputIcon">
          <el-input style="margin-top: 20px;" placeholder="" v-model="searchValue">
          </el-input>
          <img src="@/assets/img/courseTechingimg/inputSearch.png" @click="searchClick()"/>
        </div>
        <div class="screen">
          <div style="display: flex;align-items: center;height: 100%;flex-wrap: wrap;align-content: flex-start;">
            <span :class="{ active: screenItemIndex == -1 }" class="screenClass" @click="quanbu()"> 全部 </span>
            <div :class="{ active: screenItemIndex == index }" class="screenClass" v-for="(item, index) in screenList" :key="index" @click="screenName(index, item.id)">
              <span>{{ item.systemExperienceName }}</span>
            </div>
          </div>
        </div>
        <ul 
        v-infinite-scroll="load" 
        style="overflow-y: auto;height: 800px;list-style: none;margin-top:15px;"
        infinite-scroll-immediate="false"
        class="ulList"
        >
        <li class="dataListRow" v-for="(item, index) in SystemExperience" :key="index" @click="rowClick(item)">
            <div style="margin: 0.8%;">
              <img style="height:100%;width:240px;object-fit: scale-down;" :src="`${baseUrl}/${item.pictureUrl}`" alt="" srcset="">
            </div>
         
            <div style="float: left;margin: 0.8%;width: 80%;">
              <p style="font-size: 1.3rem;font-weight: bold;padding-bottom: 5px;">{{ item.systemExperienceName }}</p>
              <p style="padding-bottom: 5px;"><span style="font-size: 14px;color: #666666;">系统发布时间: </span><span
                  style="font-size: 14px;">{{ item.publishTime }}</span></p>
              <p style="padding-bottom: 5px;"><span style="font-size: 14px;color: #666666;">版权所有人: </span><span
                  style="font-size: 14px;">{{ item.copyrightOwner }}</span></p>
              <p style="padding-bottom: 5px;"><span style="font-size: 14px;color: #666666;">适用专业: </span><span
                  style="font-size: 14px;">{{ item.majorName }}</span></p>
              <p style="padding-bottom: 5px;"><span style="font-size: 14px;color: #666666;">适用行业: </span><span
                  style="font-size: 14px;">{{ item.industryName }}</span></p>
              <p style="text-align: end;font-size: 14px;"><span
                  style="font-size: 14px;color: #666666;">实训次数:</span><span>{{ item.trainingCount }}</span></p>
            </div>
          </li>
        </ul>
      </div>
    </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setStorage ,getStorage} from "@/js/common";
// import router from "../../router/index.js";
import { coursePageList } from '@/js/caseApi'
import {f_get_systemExperiencePageList,get_select_systemExperience} from "@/js/homeListApi.js"
import {baseUrl} from '@/js/util.js'
import { ElMessage } from 'element-plus';
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      baseUrl: baseUrl,
      userId: getStorage('userId'),
      icList: [],
      searchValue: '',
      screenItemIndex: -1,
      screenList: [
        {
          name: '智慧设计',

        }, {
          name: '智能制造',

        }, {
          name: '智慧施工',

        }, {
          name: '智慧运维',

        },
      ],
      SystemExperience: [
        // {
        //   imgUrl: require('@/assets/img/courseTechingimg/xitong.png'),
        //   title: '智慧工地系统',
        //   update: '2022-10-29',
        //   author: '合肥工业大学',
        //   according: '智能建造',
        //   industry: '建筑',
        //   PracticeTimes: '1314'
        // }, {
        //   imgUrl: require('@/assets/img/courseTechingimg/xitong.png'),
        //   title: '结构监测系统',
        //   update: '2022-10-29',
        //   author: '合肥工业大学',
        //   according: '智能建造',
        //   industry: '建筑',
        //   PracticeTimes: '1245'
        // },

      ],
      currentPage4: 1,
      pageIndex: 1,
      pageSize: 10,
      stageId: '',//阶段id
      num: 2,
    });
    // const setbclist = (arr) => {
    //   store.commit("Setbclist", arr);
    //   setStorage("bclist", arr);
    // }
    const methods = {
      //获取系统实训分页数据
      getsystemList(){
        let datas = {
          stageId:state.stageId,
          systemExperienceName:state.searchValue,
          pageIndex:state.pageIndex,
          pageSize:state.pageSize,
        }
        f_get_systemExperiencePageList(datas).then((res) => {
          if (res.data.code == 1) {
            console.log(res.data.data.list, '系统实训分页数据');
            state.SystemExperience = res.data.data.list;
          } else {
            ElMessage.error(res.data.message)
          }
        })
      },
      //获取阶段
      getscreenList(){
        let datas = {
          systemExperienceName:'',
        }
        get_select_systemExperience(datas).then((res) => {
          if (res.data.code == 1) {
            console.log(res.data.data, '系统实训获取阶段');
            state.screenList = res.data.data;
          } else {
            ElMessage.error(res.data.message)
          }
        })
      },

       //下拉加载更多
      load() {
        let datas = {
          pageIndex: state.num++,
          pageSize: state.pageSize,
          stageId:state.stageId,
          systemExperienceName:state.searchValue,
        }
        f_get_systemExperiencePageList(datas).then((res) => {
          if (res.data.code == 1) {
            res.data.data.list.forEach(item => {
              if (item) {
                state.SystemExperience.push(item)
              }
            });
            // state.count += res.data.data.totalCount.Value
            console.log(state.SystemExperience, "系统实训分页数据222");
          } else {
            ElMessage.error(res.data.message)
          }
        })
      },

      //点击搜索
      searchClick() {
        methods.getsystemList();
      },
      //点击全部
      quanbu() {
        state.screenItemIndex = -1;
        state.stageId = '';
        methods.getsystemList();
      },
      //点击切换tab
      screenName(index, Id) {
        state.screenItemIndex = index;
        state.stageId = Id;
        methods.getsystemList();

      },
      //点击跳转详情
      rowClick(item) {
        setStorage('intelitem', item)
        router.push("/home/intelligentConstructionDetail");
      },


      // 获取智能制造课程
      getDataList() {
        let datas = {
          pageIndex: state.currentPage,
          pageSize: state.pageSize,
          phase: 1//（1、智慧建造、2数字设计、3、智慧施工、4、智慧运维）
        }
        coursePageList(datas).then((res) => {
          let { code, data } = res.data;
          if (code == 1 && data && data.courseList) {
            state.icList = data.courseList;
            state.total = data.totalCount.Value
          }
        })
      },
      handleCurrentChange: (val) => {
        state.currentPage = val;
        methods.getDataList()
      },
      handleSizeChange: (val) => {
        state.pageSize = val;
        methods.getDataList()
      },
      seefn: () => {
        store.commit("SetnarbarMenuActive", "4s");
        setStorage("narbarMenuActive", "4s");
        router.push("/home/igintelzhgd");
        // window.open('http://sxgd.hiaipc.com/#/home/index')
      },
      toDetail: (item, datas) => {
        console.log(item);

        let arr = [
          {
            text: "智能制造",
            nindex: "4",
            path: "/home/intelligentConstruction",
            back: false
          },
          {
            text: "课程介绍",
            nindex: "",
            path: "",
            back: false
          }
        ]
        setbclist(arr);
        // store.commit("SetnarbarMenuActive", "5s");
        // setStorage("narbarMenuActive", "5s");
        setStorage('detailData', item)
        store.commit('SetdetailData', item)
        router.push("/home/intelligentConstructionDetail");


      },
      majorfn: (item) => {
        state.majoractive = item.id;
      },
      jobfn: (item) => {
        state.jobsactive = item.id;
      },
    };
    onMounted(() => {
      // methods.getDataList()
      methods.getsystemList();
      methods.getscreenList();


    });
    onUnmounted(() => { });
    // provide('popfalse',state.popfalse)
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
#digitalDesign {
  height: 100%;
  position: relative;
  background: #F7F8FA;
  .cm-container{
    width: 66.5%;
    height: 100%;
    margin: 0 auto;
    :deep .el-input__inner {
      border-radius: 50px !important;
      border: none;
    }
  }
  .inputIcon{
    display: flex;
    justify-content: center;
    position: relative;

  }
  .inputIcon img{
    position: absolute;
    top: 25px;
    right: 10px;
    width: 30px;
    height: 30px;
    object-fit: contain;
    cursor: pointer;
  }
  .screen{
    width: 100%;
    background: #fff;
    margin-top: 20px;
    border-radius: 5px;
    .screenClass{
        float: left;
        font-size: 14px;
        padding: 10px 20px 10px 20px;
        border-radius: 8px;
        margin: 5px 0 5px 20px;
        cursor: pointer;
    }
    .active{
        background: #D3EDFC;
        color: #76C8F6;
    }
  }
  .ulList::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background-color: transparent;
}
.ulList::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
}
.ulList::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px transparent;
    border-radius: 10px;
    background-color: transparent;
}
  .dataList{
    width: 100%;
    // height: 800px;
    // background: #fff;
    margin-top: 10px;
  }
  .dataListRow{
    width: 100%;
    height: 180px;
    background: #fff;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
  }
  .dataListRow:hover{
    box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.06);
  }
  .el-pagination{
    background: none
  }
  
}

//@media screen and (min-width: 1600px) {
//  #digitalDesign {
//    .cm-container {
//      width: 1400px;
//
//      .cm-img {
//        .cm-img-list {
//          width: 264px;
//          height: 244px;
//
//          img {
//            width: 264px;
//            height: 198px;
//          }
//
//          &:nth-child(5n) {
//            margin-right: 0;
//          }
//        }
//      }
//    }
//  }
//}

</style>
